<template>
    <main id="statistique" v-cloak>
        <HeaderTab :title="$t('horse.statistique')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="row">
                <div class="col-12">
                    <div>
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "HorseStatistiques",
		mixins: [Navigation],
		data () {
			return {
                param_tab: '',
                actual_tab: 'pensionListe',
                swiper_tabs: [],
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab
            this.swiper_tabs = [
                {
                    id: 'pensionListe',
                    label: 'horse.horse_pension',
                    active: true,
                    href : 'pensionListe'
                }
            ]
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
